.justifier {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.editorWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
}

.paragraph {
    width: calc(20.76vw + 711.39px);
}

@media(min-width:1920px) {
    .paragraph {
        width: 1110px;
    }
}

@media(max-width:1000px) {
    .paragraph {
        width: calc(124.138vw - 322.38px);
    }
}

@media(max-width:768px) {
    .paragraph {
        width: calc(64.5833vw + 135px);
    }
}

@media(max-width:480px) {
    .paragraph {
        width: calc(91.66vw + 5px);
    }
}

@media(max-width:360px) {
    .paragraph {
        width: 335px;
    }
}

.headerProduct {
    background: rgba(216, 216, 216, 0.4);
    display: flex;
}

.inputDescriptionStyle {
    Border: none;
    outline: none;
    color: #201644;
    /* mini */
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.inputNameStyle {
    Border: none;
    outline: none;
    color: var(--text, #201644);
    /* accent */
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.saveButton {
    background: #0092DD;
    padding: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    color:white
}

.saveButtonWrapper {
    display: flex;
    justify-content: center;
    color: white;
    margin-bottom: 30px;
}
.breadcrumbsStyle {
    flex: 1110px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0092DD;
    font-size: 13px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbsElement:hover{
    text-decoration: underline;
    cursor: pointer;
}