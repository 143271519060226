.headerElement {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #201644;
    margin-left: 30px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 25px 5px 25px 25px;
}

@media (max-width:600px) {
    .headerElement {
        margin-left: 1%;
    }
}

.headerElement:hover {
    /* background: rgba(216, 216, 216, 0.4); */
    text-decoration: underline;
}

.phoneText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    /* identical to box height, or 26px */

    display: flex;
    align-items: center;

    color: #201644;
}

.link {
    color: #201644;
}

.link:active {
    color: #201644;
}
