.carouselWrapper{
    display: flex;
    flex-direction: column;
    height: 250px;
    overflow: hidden;
}
.carouselWrapper:active {
    outline: none;
}
.carouselWrapper:focus  {
    outline: none;
}
.carouselWrapper::-moz-focus-inner {
    border: 0;
  }
.carouselElement{
    width: 80px;
    height: 80px;
    transition: 0.5s;
    animation: move 500ms ease-in;
}
.carouselElementFocused{
    display: flex;
    width: 230px;
    height: 230px;
    transition: 0.5s;
    position: absolute;
    margin-left: 90px;
    animation: move 500ms ease-in;
}
.carouselElementHidden{
    height: 0px;
    width: 0px;
    margin-left: 20px;
    margin-top: 50px;
    position: absolute;
    transition: 0.5s;
    opacity: 0;
}
@keyframes move {
    0% {
      offset-distance: 0%;
    }
    100% {
      offset-distance: 100%;
    }
  }
.bottomArrow{
    position: absolute;
    margin-top: 180px;
    display: flex;
    z-index: 1;
}