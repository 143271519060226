.holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.wrapper {
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.splash {
    position: absolute;
    margin-top: -85px;
    width: 100%;
    z-index: -1;
    min-height: 710px;
}

.aboutPartnerBlockImage {
    width: auto;
    object-fit: cover;
}

@media (max-width:1600px) {
    .splash {
        min-height: 160px;
    }
}

.topLeftTab {
    width: 660px;
    height: 270px;
    background: rgba(216, 216, 216, 0.4);
    border-radius: 0px 5px 25px 0px;

    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.topLeftTabAligner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 197px;
    margin-top: 31px;
    margin-right: 57px;
}

.topLeftTabHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.4487179487179487vw + 11.384615384615385px);
    line-height: 23px;
    cursor: pointer;
}

.topLeftTabHeader:hover {
    color: #808080;
}

.topLeftTabText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.4487179487179487vw + 11.384615384615385px);
    line-height: 23px;
    cursor: pointer;
}

.topLeftTabText:hover {
    color: #808080;
}

.topTabs {
    margin-top: 145px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.topRightTab {
    width: 825px;
    background: rgba(246, 246, 246, 0.75);
    border-radius: 25px;
    margin-left: 30px;
    z-index: 2;
}

.topRightTabHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.8974358974358974vw + 14.76923076923077px);
    line-height: 38px;
    margin-left: 47px;
    margin-top: 25px;
}

.topRightTabText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.2564102564102564vw + 11.076923076923077px);
    line-height: 130%;
    display: inline-block;
    justify-content: center;
    margin-left: 47px;
    margin-right: 48px;
    margin-top: 30px;
    margin-bottom: 25px;
    flex-direction: column;
    text-align: justify;

}

.achievementHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.8974358974358974vw + 14.76923076923077px);
    line-height: 38px;
    margin-left: 40px;
    color: #201644;
}

.achievementHolder {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 50px;
}

.headerVistavkaText {
    color: var(--text, #201644);
    /* accent */
    font-family: "Raleway";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
text-align: center;
margin-top: 50px;
margin-bottom: 30px;
}

@media (max-width:680px) {
    .achievementHolder {
        justify-content: center;
    }
}




.achievementBlock {
    width: calc(4.294871794871795vw + 104.53846153846153px);
    height: calc(2.1153846153846155vw + 82.38461538461539px);
    background: rgba(197, 226, 241, 0.3);
    box-shadow: 2px 2px 8px rgba(89, 116, 130, 0.25);
    border-radius: 25px 5px 25px 25px;
    margin-right: 25.55px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.achievementBlockHeader {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: calc(1.0256410256410256vw + 12.307692307692307px);
    line-height: 38px;
    text-align: center;
    margin-top: 15px;
    color: #201644;
}

.achievementBlockText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.19230769230769232vw + 12.307692307692308px);
    line-height: 130%;
    text-align: center;
    margin-top: calc(0.5128205128205128vw - 1.8461538461538463px);
    color: #201644;
}

.tabMargin {
    margin-left: calc(28.125vw + 150px);
}

.tabMargin2 {
    margin-right: calc(28.125vw + 150px);
}

@media (max-width:768px) {
    .tabMargin {
        margin-left: 20px;
    }

    .tabMargin2 {
        margin-right: 20px;
    }

}

.certificateSidebar {
    z-index: 10;
    padding-right: 37px;
    width: 20px;
}

.certificateSidebar2 {
    /* z-index: 10;
    padding-right: 37px; */
    width: 20px;
}

.certificateHolder {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
}

.certificateWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

}

.certificateHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.2564102564102564vw + 11.076923076923077px);
    line-height: 130%;
    margin-top: 15px;
    color: #201644;
}

.certificateHeader2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.2564102564102564vw + 11.076923076923077px);
    line-height: 130%;
    margin-top: 15px;
    margin-left: 700px;
    color: #201644;
}

.certificateScroller {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-top: 55px;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.certificateScroller::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.certificateScrollButtons {
    margin-left: calc(34.72222222222222vw - 166.66666666666663px);
    margin-top: 50px;
}

@media (max-width:768px) {
    .certificateScrollButtons {
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }
}

.scrollLeft {
    margin-right: 20px;
    cursor: pointer;
}

.scrollRight {
    margin-left: 20px;
    cursor: pointer;
}

.partnersWrapper {
    margin-top: 140px;
    display: flex;
    justify-content: center;
}

.partnersHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.8974358974358974vw + 14.76923076923077px);
    line-height: 38px;
    margin-left: 47px;
    color: #201644;
    text-align: center;
}

.partnersBox {
    margin-left: 120px;
    margin-bottom: 70px;
    max-width: 950px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

}

@media (max-width:480px) {
    .partnersWrapper {
        display: flex;
        justify-content: center;
    }
}

.adoptiveMenuWrap {
    margin-top: 10px;
    height: 20px;
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

@media (max-width:359px) {
    .splash {
        height: 250px;
    }

    .adoptiveMenuWrap {
        display: flex;
        flex-direction: column;
        height: 100px;
        text-align: center;
    }
}

.menuWrapper {
    background: #C5E2F1;
    border-radius: 0px 5px 15px 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.menuElement {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 25px;
    cursor: pointer;
    padding-left: 5px;
}

.menuElement:hover {
    color: #808080;
}

@media (max-width:850px) {
    .menuElement {
        width: 100px;
    }
}

@media (max-width:600px) {
    .menuElement {
        width: 300px;
    }

    .menuElement:hover {
        color: #808080;
    }
}

.subDirectoryElement {
    border: 1px solid #0092DD;
    border-radius: 15px 5px 15px 15px;


    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
}

.subDirectoryElement:hover {
    background: linear-gradient(175.2deg, #C5E2F1 3.88%, rgba(197, 226, 241, 0) 50.02%);

    align-items: stretch;
}

.subDirectoryElement:last-child {
    margin-right: 0;
    align-items: stretch;
}

.subDirectoriesWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;


}

.wrapProd {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    transition: box-shadow 0.3s;
}

.wrapProd:hover {
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.3);
}

.wrapProd:last-child {
    margin-right: 0px;
}

.wrapProd2 {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
}

.wrapProd2:last-child {
    margin-right: 0px;
}

.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
}


.inputStyle {
    Border: none;
    outline: none;
    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);

}

.inputDescriptionStyle {
    Border: none;
    outline: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
}

.labName {

    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.1282051282051282vw + 10.538461538461538px);
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);
}

.labDescription {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.1282051282051282vw + 13.538461538461538px);
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
}

.footerWrapper {
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    height: 170px;
    align-items: center;
    width: 1920px;
    justify-content: center;

}

.warningText {
    margin-left: 20px;
    margin-right: 40px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: #201644;
    display: flex;
    align-items: center;
    flex: 1;
}

.backgraundGradient {
    height: 250px;
    width: 100%;

    /* background: red; */
    position: absolute;
    top: 0;
    left: 0;
}

.smartMenu {
    background: #C5E2F1;
    box-shadow: 0px 4px 10px rgba(89, 116, 130, 0.25);
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.smartMenuV2 {
    background: #C5E2F1;
    box-shadow: 0px 4px 10px rgba(89, 116, 130, 0.25);
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
}

.margen {
    height: 10px;
    background: rgba(216, 216, 216, 0.4);
}

.shadow {
    background: rgb(0, 0, 0, 0.6);
    /* opacity: 0.6; */
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createButtonWrapper {
    background: #0092DD;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
}


.closeButtonWrapper {
    position: absolute;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: -7px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.closeButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.plusWrapper {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
    min-height: 70px;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: #0092DD;
    font-size: 50px;
}

.editButtonWrapper {
    position: absolute;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: 15px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.editButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.modalWrapper {
    width: 500px;
    height: 200px;
    background: white;
    border-radius: 16px;
}

.modalText {
    margin-top: 10px;
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    color: #201644;
}

.alertButtonWrapperDelete {
    color: #0092DD;
    border: 1px solid #0092DD;
    border-radius: 16px;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin-left: 50px;
    margin-top: 30px;
    cursor: pointer;
}

.alertButtonWrapperCancel {
    cursor: pointer;
    margin-top: 30px;
    color: #0092DD;
    border: 1px solid #0092DD;
    border-radius: 16px;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin-right: 50px;
}

.alertButtonWrapperDelete:hover {
    background: rgb(0, 146, 221, 0.05);
}

.alertButtonWrapperCancel:hover {
    background: rgb(0, 146, 221, 0.05);
}

.breadcrumbsStyle {
    flex: 1110px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0092DD;
    font-size: 13px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbsElement:hover {
    text-decoration: underline;
    cursor: pointer;
}

.link {
    text-decoration: none;
}

.link:active {
    text-decoration: none;
    /* color: #201644; */
}

.kpWrapper {
    cursor: pointer;
    z-index: 400;
    position: fixed;
    right: 40px;
    bottom: 30px;
    border-radius: 20px 5px 20px 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #0092DD;
    display: inline-flex;
    padding: 16px 20px;
    align-items: center;
    gap: 15px;
    user-select: none;

}

.textInterw {
    font-size: 36px;
    font-family: Raleway;
    font-weight: 500;
    line-height: 130%;
}