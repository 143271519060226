.FooterText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    color: #201644;
}

.FooterHeaderText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration-line: underline;
    color: #201644;
}