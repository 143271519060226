.footerWrapper {
    margin-top: 30px;
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    height: 170px;
    align-items: center;
    width: 1920px;
    justify-content: center;

}

.warningText {
    margin-left: 20px;
    margin-right: 40px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: #201644;
    display: flex;
    align-items: center;
    flex: 1;
}


.productHeader {
    background: rgba(216, 216, 216, 0.4);
    position: relative;
    height: 300px;
}


.menuWrapper {
    background: #C5E2F1;
    border-radius: 0px 5px 15px 0px;
}

.headerProduct {
    background: rgba(216, 216, 216, 0.4);
    display: flex;
}

.headerText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
}

.priceText {
    margin-top: 20px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;

    /* text */
    margin-bottom: 20px;
    color: #201644;
}

.discriptionText {
    width: 40vw;
    margin-top: 20px;
    flex: 1;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    display: flex;
    align-items: center;
    /* text */
    color: #201644;
}

.headerWrap {
    display: flex;
}

.text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
}

.buttonWrap {
    border: 1px solid #0092DD;
    border-radius: 10px 1px 0px 0px;
    margin-bottom: -1px;
    margin-right: 10px;
    padding: 7px;
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
    position: relative;
}

.buttonWrap:hover {
    background: rgba(216, 216, 216, 0.4);
}

.closeButton {
    position: absolute;
    right: -8px;
    top: -8px;
    background: white;
    border: 1px solid #0092DD;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

}

.closeButton:hover {
    background: rgba(216, 216, 216, 1);
    z-index: 5;
}

.productTextHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #201644;
}

.productText {
    font-family: 'Raleway';
    font-style: normal;

    font-size: 16px;
    line-height: 130%;
    color: #201644;
}

.poTextWrapper {
    margin-top: 40px;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.flexMagic {
    flex: 1;
}

.supportWrapper {
    background: rgba(216, 216, 216, 0.7);
    border-radius: 15px 5px 15px 15px;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 20px;
    margin-top: 30px;
}

.supportText {
    width: 210px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
}

.shadow {
    background: rgb(0, 0, 0, 0.6);
    /* opacity: 0.6; */
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shadow2 {
    background: black;
    opacity: 0.6;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.addImage {
    width: 232px;
    height: 212px;
    border: 4px solid black;
    margin: 10px;
}

.addImagePlusStyle {
    color: black;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    cursor: pointer;
}

.closeButtonWrapper {
    position: absolute;
    color: white;
    background: rgb(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: -7px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.buttonWrapper {
    color: #0092DD;
    border: 1px solid;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    margin: 10px;
    cursor: pointer;
}

.inputPriceStyle {
    background: rgba(0, 0, 0, 0);
    Border: none;
    outline: none;
    font-family: 'Raleway';
    color: var(--text, #201644);
    font-size: 20px;
}

.inputDescriptionStyle {
    background: rgba(0, 0, 0, 0);
    Border: none;
    outline: none;
    font-family: 'Raleway';
    color: var(--text, #201644);
    font-size: 20px;
}

.saveButtonWrapper {
    display: flex;
    justify-content: center;
    color: white;
}

.saveButton {
    background: #0092DD;
    padding: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
}


@media (max-width:768px) {
    .flexMagic {
        flex: 0;
    }

    .poTextWrapper {
        flex-direction: column;
    }

    .headerWrap {
        flex-direction: column;
    }

    .discriptionText {
        width: 100%;
    }
}

.link {
    color: #201644;
}

.link:active {
    color: #201644;
}

.editorWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
}

.viewWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
}

.justifier {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.breadcrumbsStyle {
    flex: 1110px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0092DD;
    font-size: 13px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbsElement:hover {
    text-decoration: underline;
    cursor: pointer;
}