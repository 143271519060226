body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),
      url('./Font/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
      url('./Font/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
      url('./Font/Raleway/static/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
      url('./Font/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: local('Roboto-LightItalic'),
      url('./Font/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.ymaps-2-1-79-gototaxi__container{
  opacity: 0;
}

.ymaps-2-1-79-gotoymaps__text{
  opacity: 0;
}
.ymaps-2-1-79-gotoymaps__pin{
  opacity: 0;
}
.ymaps-2-1-79-gotoymaps__container{
  opacity: 0;
}
.ymaps-2-1-79-gototech{
  opacity: 0;
}
.ymaps-2-1-79-copyright__link{
  opacity: 0;
}
.ymaps-2-1-79-copyright__text{
  opacity: 0;
}
.ymaps-2-1-79-copyright__content{
  opacity: 0;
}

.css-13cymwt-control {
  opacity: 0;
  cursor: pointer;
 }
 
 .css-t3ipsp-control {
   opacity: 0;
   cursor: pointer;
   transition: 1s;
 }

.css-qbdosj-Input{
  cursor: pointer;
}

.css-1xc3v61-indicatorContainer{
  cursor: pointer;
}

.css-1fdsijx-ValueContainer{
  cursor: pointer;
}
 

.App {
  font-family: sans-serif;
  /* text-align: center; */
}
