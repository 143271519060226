.buttonWrapper {
    position: absolute;
    bottom: 0;
    border-radius: 10px 1px 10px 10px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: 2px solid #0092DD;
}
.buttonText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0092DD;
}
